import { Client } from '@googlemaps/google-maps-services-js';
import {
  Box,
  Button,
  Paper,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  StyleRules,
  Theme,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { ECategoryType } from '@models/category-type';
import { IQuery } from '@models/query';
import { IRegistrationState } from '@models/registration-state';
import { ESerialActionType } from '@models/serial-action-type';
import { ISerials } from '@models/serials';
import { IStateApps } from '@models/state-app';
import { IStateServers } from '@models/state-servers';
import { IStoreState } from '@models/store-state';
import {
  clearScannedSerialCode,
  setDistributorShipState,
  setTab,
} from '@redux/actions/appsActions';
import {
  getMasterLocations,
  getScannedSerials,
  registerSerials,
  setMasterLocationsQuery,
  storeTmpGpsCords,
  registerScanGpsCords,
} from '@redux/actions/serversActions';
import appLanguages from '@utils/app-languages';
import { getCurrentGeoLocation, getScannedLocationInfo } from '@utils/get-scanned-location-info';
import { checkSerialValidity } from '@utils/check-serial-validity';
import {
  getAutocomplete,
  getScannedLocationDistanceView,
  getScannedSerialsView,
  getTextField,
  getTextFieldForReadOnly,
  GOOGLE_MAPS_PROHIBITED_TERRITORIES,
  setDistanceFromScanLocToUserLocByGoogleMapAPI,
  setScanLocationInfoByGoogleMapAPI,
} from '@utils/common';
import { DefaultMasterQuery } from '@utils/default-query';
import { getQueryForScannedSerials } from '@utils/get-scanned-serials-for-registration';
import React from 'react';
import { connect } from 'react-redux';
import userEnv from 'userEnv';

import { compose } from 'redux';

import { SerialQRScan } from './SerialQRScan';
import type { GeolocateResponseData } from '@googlemaps/google-maps-services-js/dist/geolocate';

class SakeShipForDistributorClass extends React.PureComponent<Props, State> {
  protected initState: State;

  constructor(props) {
    super(props);
    this.initState = {
      scanLat: 0,
      scanLng: 0,
      scanCountry: '',
      scanArea: '',
      scanCity: '',
      scanSubCity: '',
      scanPostalCode: '',
      distanceText: '',
      distance: 0,
      warning: false,
      openScannedSerials: true,
      openSerialBrandMap: {},
      openLocations: false,
      isFetchingGps: true,
    };
    this.state = {
      ...this.initState,
    };
  }
  restValue = { id: 0, name: '', mapAddress: '', mainAddress: '', customerCode: '' };

  public async componentDidMount() {
    // 拠点（レストラン）データ取得
    const {
      setMasterLocationsQuery,
      getMasterLocations,
      servers: { user },
    } = this.props;
    const query = {
      ...DefaultMasterQuery,
      // This will display the associated restaurants from this distributor user
      showLocationCategory: ECategoryType.DISTRIBUTOR,
    };
    setMasterLocationsQuery(query);
    getMasterLocations(query);
    const userLocationCountry = user?.location?.country;
    if (GOOGLE_MAPS_PROHIBITED_TERRITORIES.includes(userLocationCountry)) {
      await this.initScanLocationInfo();
    } else {
      await this.initScanLocationByGoogleMapAPI();
    }
  }

  getStepContent(step) {
    const {
      classes,
      apps,
      servers,
      apps: {
        distributorShipState: {
          restLocId,
          slipNum,
          subCategory,
          customerNumber,
          eventName,
          purpose,
        },
      },
    } = this.props;
    const { user } = servers;
    const { location } = user;
    const lang = apps.currentLanguage;

    // restaurants
    const defaultRest = 0;
    //let restValue = { id: defaultRest, name: '' };
    const rests = servers?.locations?.map((locObj) => {
      let displayLabel = locObj.name;
      if (locObj.id === restLocId) this.restValue = { ...locObj };

      const suffix = [
        locObj?.customerCode !== locObj?.name && locObj?.customerCode,
        locObj?.mainAddress,
      ]
        .filter((a) => a)
        .join('：');

      if (suffix) {
        displayLabel = `${locObj?.name}（${suffix}）`;
      }

      return { ...locObj, displayLabel };
    });

    // ストランと伝票番号を設定する
    const step1View = (
      <>
        {getAutocomplete(
          `${appLanguages.restaurant2[lang]}`,
          `${appLanguages.pleaseSelect[lang]}`,
          true,
          { ...this.restValue },
          [...rests],
          (e, v) => this.handleChangeRestLocId(v ? v.id : defaultRest, v.subCategory),
        )}
        {getTextField(
          `${appLanguages.salesOrderNumber[lang]}`,
          `${appLanguages.pleaseEnter[lang]}`,
          `${slipNum}`,
          (e) => this.handleChangeSlipNum(e),
        )}
        {subCategory === 5 &&
          getTextField(
            `${appLanguages.enterCustomerNumber[lang]}`,
            `${appLanguages.pleaseEnter[lang]}`,
            `${customerNumber || ''}`,
            (e) => this.handleChangeCustomerNumber(e),
          )}
        {subCategory === 6 &&
          getTextField(
            `${appLanguages.enterEventName[lang]}`,
            `${appLanguages.pleaseEnter[lang]}`,
            `${eventName || ''}`,
            (e) => this.handleChangeEventName(e),
          )}
        {subCategory === 7 &&
          getTextField(
            `${appLanguages.enterPurpose[lang]}`,
            `${appLanguages.pleaseEnter[lang]}`,
            `${purpose || ''}`,
            (e) => this.handleChangePurpose(e),
          )}
      </>
    );

    // QRコードをスキャンする
    const step2View = <SerialQRScan operation={1} />;

    // 最終確認
    const step3View = (
      <>
        <Paper elevation={3} className={classes.paper}>
          {getTextFieldForReadOnly(appLanguages.restaurant2[lang], this.restValue.name)}
          <Box mt={3} />
          {getTextFieldForReadOnly(appLanguages.mainAddress[lang], this.restValue.mainAddress)}
          <Box mt={3} />
          {getTextFieldForReadOnly(
            appLanguages.customerCode[lang],
            this.restValue.customerCode || '-',
          )}
          <Box mt={3} />
          {getTextFieldForReadOnly(appLanguages.customerCode[lang], slipNum || '-')}
          <Box mt={3} />
          {subCategory === 5 &&
            getTextFieldForReadOnly(appLanguages.enterCustomerNumber[lang], customerNumber || '-')}
          <Box mt={3} />
          {subCategory === 6 &&
            getTextFieldForReadOnly(appLanguages.enterEventName[lang], eventName || '-')}
          <Box mt={3} />
          {subCategory === 7 &&
            getTextFieldForReadOnly(appLanguages.enterPurpose[lang], purpose || '-')}
          <Box mt={2} />
          {/* シリアル番号一覧 */}
          {getScannedSerialsView(
            [...servers?.scannedSerials],
            (serial) => this.invalidSerial(serial),
            this.state,
            (s) => this.setState(s),
            classes,
            'outgoing',
          )}
          <Box mt={3} />
          {/* 拠点登録位置情報からスキャン位置情報の距離 */}
          {getScannedLocationDistanceView(
            location,
            classes,
            this.state,
            (s) => this.setState(s),
            'outgoing',
          )}
        </Paper>
      </>
    );

    switch (step) {
      case 0:
        return step1View;
      case 1:
        return step2View;
      case 2:
        return step3View;
      default:
        return 'Unknown step...';
    }
  }

  protected async initScanLocationInfo() {
    const {
      servers: { user },
    } = this.props;
    const { distanceText, distance, scanLat, scanLng } = await getScannedLocationInfo(user);
    this.setState({ distanceText, distance, scanLat, scanLng });
  }

  protected async initScanLocationByGoogleMapAPI() {
    const { servers } = this.props;
    const { user } = servers;

    const updateLocationInfo = (latitude: number, longitude: number) => {
      setScanLocationInfoByGoogleMapAPI(latitude, longitude, (s) => this.setState(s));
      setDistanceFromScanLocToUserLocByGoogleMapAPI(user.location, latitude, longitude, (s) =>
        this.setState(s),
      );
    };

    try {
      const position = await getCurrentGeoLocation();
      const { latitude, longitude } = position.coords;

      // Google Map API (geolocate API) での位置情報取得
      new Client({})
        .geolocate({ params: { key: userEnv.googleMapApiKey }, data: null })
        .then((response) => {
          const data = response.data as GeolocateResponseData;
          const location = data?.location;
          if (location?.lat && location?.lng) {
            updateLocationInfo(location.lat, location.lng);
          } else {
            updateLocationInfo(latitude, longitude);
          }
        })
        .catch(() => updateLocationInfo(latitude, longitude));
    } catch (error) {
      console.error('initScanLocationByGoogleMapAPI error:', error);
    }
  }

  clearAppState() {
    this.props.setDistributorShipState({
      activeStep: 0,
      restLocId: 0,
      slipNum: '',
    });
    this.setState({ ...this.initState });
  }

  protected invalidSerial(serial) {
    const {
      servers: { user },
    } = this.props;
    return checkSerialValidity(user, serial) !== 'ok';
  }

  disableNextStep(step) {
    const {
      servers,
      apps: {
        distributorShipState: { restLocId },
        scannedSerialCodes,
      },
    } = this.props;
    switch (step) {
      case 0:
        return !restLocId;
      case 1: {
        // QRコードスキャン
        // Sreenath 27thOct - Scan Processing of Deleted/not registered Serial Number
        const results = servers?.scannedSerials.filter((serial) => !this.invalidSerial(serial));
        let scannedList = []; // array the scanned serials which are available to be shipped/recieved/sold.
        scannedSerialCodes.forEach((x) => {
          // Array of scanned serials regardless of registered or not
          const isRegistered = results.find((serial) => serial.code === x); // find only the registered ones
          if (isRegistered) {
            scannedList.push(isRegistered);
          }
        });
        return !scannedList.length; // if this array is empty meaning no valid serials thus disable the next button in step form.
      }
      case 2:
        return false;
      default:
        return true;
    }
  }

  async handleNextStep() {
    const {
      apps: { distributorShipState, scannedSerialCodes },
      setDistributorShipState,
      setTab,
      servers,
      clearScannedSerialCode,
      getScannedSerials,
      registerSerials,
      registerScanGpsCords,
    } = this.props;
    const newActiveStep = distributorShipState?.activeStep + 1;
    setDistributorShipState({
      ...distributorShipState,
      activeStep: newActiveStep,
    });

    if (newActiveStep === 2) {
      // Get all serials to register
      await getScannedSerials(getQueryForScannedSerials(scannedSerialCodes));
    }
    if (newActiveStep > 2) {
      const { user, gps } = servers;
      const { location } = user;
      const { scanLat, scanLng, warning } = this.state;

      // let latestDist = distance;
      let latestLat = scanLat;
      let latestLnt = scanLng;
      gps.sort(function (a, b) {
        if (a.distance > b.distance) {
          return 1;
        } else {
          return -1;
        }
      });
      if (gps.length && gps[0]['fetchedLatitude'] && gps[0]['fetchedLongitude']) {
        latestLat = gps[0]['fetchedLatitude'];
        latestLnt = gps[0]['fetchedLongitude'];
      }
      const distLocId = location ? location.id : null;
      const distShipAt = new Date().toISOString();
      const distShipScanLatLng = latestLat && latestLnt ? `${latestLat},${latestLnt}` : null;
      const geocode = {
        latitude: latestLat ? latestLat : null,
        longitude: latestLnt ? latestLnt : null,
      };

      const registeringSerials = [];
      for (const serial of servers?.scannedSerials) {
        if (!this.invalidSerial(serial)) {
          const bulk = {
            ...serial,
            distSlip: distributorShipState?.slipNum,
            distLocId: distLocId,
            distShipAt,
            distShipScanLatLng,
            distShipRestLocId: distributorShipState?.restLocId,
            distShipWarning: warning,
            requireSurveyForDistShip: warning,
            distShipUserId: user.id,
            customerNumber: distributorShipState?.customerNumber,
            eventName: distributorShipState?.eventName,
            purpose: distributorShipState?.purpose,
          };
          registeringSerials.push(bulk);
        }
      }

      await registerSerials({
        action: ESerialActionType.SHIP,
        slip: null,
        distSlip: distributorShipState?.slipNum,
        geocode: geocode,
        serials: registeringSerials,
      });

      this.setState({ isFetchingGps: false });
      registerScanGpsCords(gps);

      // Clear app
      this.clearAppState();
      getScannedSerials({ ...DefaultMasterQuery, where: { id: 0 } }); // 空っぽにする
      clearScannedSerialCode();
      await this.initScanLocationByGoogleMapAPI();
      setDistributorShipState({
        ...distributorShipState,
        activeStep: 0, // 初期状態へ
      });

      // Go to 出荷済み Tab
      setTab(3);
    }
  }

  handleBackStep() {
    const {
      apps: { distributorShipState },
      setDistributorShipState,
    } = this.props;
    const backStep = distributorShipState.activeStep - 1;
    setDistributorShipState({ ...distributorShipState, activeStep: backStep });
  }

  handleChangeRestLocId(id, subCategory) {
    const {
      apps: { distributorShipState },
      setDistributorShipState,
    } = this.props;
    setDistributorShipState({
      ...distributorShipState,
      restLocId: Number(id),
      subCategory: Number(subCategory),
    });
  }

  handleChangeSlipNum(e) {
    const {
      apps: { distributorShipState },
      setDistributorShipState,
    } = this.props;
    setDistributorShipState({ ...distributorShipState, slipNum: e.target.value });
  }

  handleChangeCustomerNumber(e) {
    const {
      apps: { distributorShipState },
      setDistributorShipState,
    } = this.props;
    setDistributorShipState({ ...distributorShipState, customerNumber: e.target.value });
  }

  handleChangeEventName(e) {
    const {
      apps: { distributorShipState },
      setDistributorShipState,
    } = this.props;
    setDistributorShipState({ ...distributorShipState, eventName: e.target.value });
  }

  handleChangePurpose(e) {
    const {
      apps: { distributorShipState },
      setDistributorShipState,
    } = this.props;
    setDistributorShipState({ ...distributorShipState, purpose: e.target.value });
  }

  render() {
    const {
      apps,
      classes,
      apps: {
        distributorShipState: { activeStep },
      },
    } = this.props;
    const lang = apps.currentLanguage;

    const stepTitles = [
      appLanguages.setShipDestAndSalesOrder[lang],
      appLanguages.registerDeliverProducts[lang],
      appLanguages.confirmAndCompleteDeliveryProductRegistration[lang],
    ];

    return (
      <>
        <Stepper activeStep={activeStep} orientation='vertical'>
          <Step key={stepTitles[0]}>
            <StepLabel>{stepTitles[0]}</StepLabel>
            <StepContent>
              {this.getStepContent(0)}
              <Box mt={3} />
              <div className={classes.actionsContainer}>
                <div>
                  <Button
                    disabled={activeStep === 0}
                    variant='contained'
                    color='secondary'
                    onClick={() => this.handleBackStep()}
                    className={classes.button}
                  >
                    {appLanguages.back[lang]}
                  </Button>
                  <Button
                    disabled={this.disableNextStep(activeStep)}
                    variant='contained'
                    color='primary'
                    onClick={() => this.handleNextStep()}
                    className={classes.button}
                  >
                    {activeStep === stepTitles.length - 1
                      ? appLanguages.finish[lang]
                      : appLanguages.next[lang]}
                  </Button>
                </div>
              </div>
            </StepContent>
            {activeStep === 1 ? (
              <Paper elevation={3} className={classes.paper}>
                {getTextFieldForReadOnly(appLanguages.restaurant2[lang], this.restValue.name)}
                <Box mt={3} />
                {getTextFieldForReadOnly(appLanguages.mainAddress[lang], this.restValue.mapAddress)}
              </Paper>
            ) : null}
          </Step>

          <Step key={stepTitles[1]}>
            <StepLabel>{stepTitles[1]}</StepLabel>
            <StepContent>
              {this.getStepContent(1)}
              <Box mt={3} />
              <div className={classes.actionsContainer}>
                <div>
                  <Button
                    disabled={activeStep === 0}
                    variant='contained'
                    color='secondary'
                    onClick={() => this.handleBackStep()}
                    className={classes.button}
                  >
                    {appLanguages.back[lang]}
                  </Button>
                  <Button
                    disabled={this.disableNextStep(activeStep)}
                    variant='contained'
                    color='primary'
                    onClick={() => this.handleNextStep()}
                    className={classes.button}
                  >
                    {activeStep === stepTitles.length - 1
                      ? appLanguages.finish[lang]
                      : appLanguages.next[lang]}
                  </Button>
                </div>
              </div>
            </StepContent>
          </Step>

          <Step key={stepTitles[2]}>
            <StepLabel>{stepTitles[2]}</StepLabel>
            <StepContent>
              {this.getStepContent(2)}
              <Box mt={3} />
              <div className={classes.actionsContainer}>
                <div>
                  <Button
                    disabled={activeStep === 0}
                    variant='contained'
                    color='secondary'
                    onClick={() => this.handleBackStep()}
                    className={classes.button}
                  >
                    {appLanguages.back[lang]}
                  </Button>
                  <Button
                    disabled={this.disableNextStep(activeStep)}
                    variant='contained'
                    color='primary'
                    onClick={() => this.handleNextStep()}
                    className={classes.button}
                  >
                    {activeStep === stepTitles.length - 1
                      ? appLanguages.finish[lang]
                      : appLanguages.next[lang]}
                  </Button>
                </div>
              </div>
            </StepContent>
          </Step>
        </Stepper>
      </>
    );
  }
}

export type Props = IStateProps & IDispatchProps;

export interface IStateProps {
  apps: IStateApps;
  servers: IStateServers;
  classes: any;
}

export interface IDispatchProps {
  getMasterLocations: (query: IQuery) => void;
  setMasterLocationsQuery: (query: IQuery) => void;
  clearScannedSerialCode: () => void;
  getScannedSerials: (query: IQuery) => void;
  registerSerials: (serialsObj: ISerials, successMessage?: string) => void;
  setDistributorShipState: (state: IRegistrationState) => void;
  setTab: (tabIndex: number) => void;
  storeTmpGpsCords: (params: any) => void; //temporaly
  registerScanGpsCords: (params: any) => void;
}

interface State {
  scanLat: number;
  scanLng: number;
  scanCountry: string;
  scanArea: string;
  scanCity: string;
  scanSubCity: string;
  scanPostalCode: string;
  distanceText: string;
  distance: number;
  warning: boolean;
  openScannedSerials: boolean;
  openSerialBrandMap: any;
  openLocations: boolean;
  isFetchingGps: boolean;
}
const mapStateToProps = (state: IStoreState): Partial<IStateProps> => ({
  apps: state.apps,
  servers: state.servers,
});

const mapDispatchToProps: IDispatchProps = {
  getMasterLocations,
  setMasterLocationsQuery,
  clearScannedSerialCode,
  getScannedSerials,
  registerSerials,
  setDistributorShipState,
  setTab,
  storeTmpGpsCords,
  registerScanGpsCords,
};

const myStyles = (theme: Theme): StyleRules => ({
  root: { width: '100%' },
  button: { marginTop: theme.spacing(1), marginRight: theme.spacing(1) },
  actionsContainer: { marginBottom: theme.spacing(2) },
  resetContainer: { padding: theme.spacing(3) },
  paper: { padding: theme.spacing(2) },
});

export const SakeShipForDistributor = compose(
  withStyles(myStyles),
  connect(mapStateToProps, mapDispatchToProps),
)(SakeShipForDistributorClass);
